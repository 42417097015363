import styled from "styled-components";

import { skeletonBgCSS } from "../utils/skeleton";

type Props = {
    isLoading?: boolean;
};

const InputGroup = styled.div<Props>`
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 12px;

    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[3]};
    background: ${({ theme }) => theme.palette.white.base};

    ${({ theme }) => theme.typography.p};

    --input-border-color: ${({ theme }) => theme.palette.grey.lighter};
    --focus-border-color: ${({ theme }) => theme.palette.purple.base};
    --focus-ring-color: ${({ theme }) => theme.palette.purple.light};
    --icon-color: ${({ theme }) => theme.palette.grey.base};

    border: 1px solid var(--input-border-color);
    box-shadow:
        0px 0px 0px 0px transparent,
        inset 0 0 0 1px var(--input-border-color);

    transition: box-shadow 0.2s;
    will-change: box-shadow;

    &:focus,
    &:focus-within {
        --icon-color: ${({ theme }) => theme.palette.purple.base};
        border: 1px solid var(--focus-border-color);
        box-shadow:
            0px 0px 0px 4px var(--focus-ring-color),
            inset 0 0 0 1px var(--focus-border-color);
    }

    --skeleton-color-base: ${({ theme }) => theme.palette.white.base};
    --skeleton-color-highlight: ${({ theme }) => theme.palette.grey.lightest};
    ${({ isLoading }) => isLoading && skeletonBgCSS}
`;

export default InputGroup;
