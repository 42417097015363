import { createCalendar, DateValue } from "@internationalized/date";
import { AriaDateFieldOptions, useDateField, useDateSegment } from "@react-aria/datepicker";
import { useObjectRef } from "@react-aria/utils";
import { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction, useRef } from "react";
import { useLocale } from "react-aria";
import { DateFieldState, type DateSegment, useDateFieldState } from "react-stately";
import styled, { css } from "styled-components";

import { Calendar } from "@sol/icons";

import InputGroup from "./InputGroup";

type DateSegmentProps = {
    segment: DateSegment;
    state: DateFieldState;
} & ComponentPropsWithoutRef<"div">;

const DateSegment = styled(function DateSegment({ segment, state, ...props }: DateSegmentProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const { segmentProps } = useDateSegment(segment, state, ref);

    return (
        <div {...props} {...segmentProps} ref={ref}>
            {segment.text}
        </div>
    );
})`
    display: inline-flex;
    align-items: center;

    padding: 0 ${({ theme }) => theme.spacing[2]};
    ${({ theme }) => theme.typography.p};

    ${({ theme, segment }) =>
        segment.isPlaceholder &&
        css`
            color: ${theme.palette.grey.light};
        `}

    :focus {
        color: ${({ theme }) => theme.palette.white.base};
        background: ${({ theme }) => theme.palette.purple.base};
        outline: none;
        border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    }
`;

type Props = {
    isLoading?: boolean;
} & AriaDateFieldOptions<DateValue> &
    ComponentPropsWithoutRef<typeof InputGroup>;

const DateInput: ForwardRefRenderFunction<HTMLDivElement, Props> = (props, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);

    const { locale } = useLocale();
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });

    const { fieldProps } = useDateField(props, state, ref);

    return (
        <InputGroup {...props} {...fieldProps} ref={ref}>
            <Calendar />
            <div>
                {state.segments.map((segment, i) => (
                    <DateSegment key={i} segment={segment} state={state} />
                ))}
            </div>
        </InputGroup>
    );
};

export default styled(forwardRef(DateInput))``;
