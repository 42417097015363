import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";

import { InputVariant, getInputVariantStyle } from "./InputStatus";
import { skeletonBgCSS } from "../utils/skeleton";

type Props = {
    isLoading?: boolean;
    variant?: InputVariant;
};

const TextArea = styled(TextareaAutosize).withConfig({
    shouldForwardProp(prop) {
        return !["isLoading", "variant"].includes(prop);
    },
})<Props>`
    ${({ theme, variant, isLoading }) => {
        return css`
            resize: none;
            width: 100%;
            min-height: 48px;
            box-sizing: border-box;

            background-color: ${theme.palette.white.base};

            padding: ${({ theme }) => theme.spacing[4]};
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

            ${theme.typography.p}

            outline: none;

            &::placeholder {
                color: ${theme.palette.grey.base};
            }

            &:disabled {
                opacity: 0.5;
            }

            ${getInputVariantStyle({ theme, variant })}

            --skeleton-color-base: ${({ theme }) => theme.palette.white.base};
            --skeleton-color-highlight: ${({ theme }) => theme.palette.grey.lightest};
            ${isLoading ? skeletonBgCSS : undefined}
        `;
    }}
`;

export default TextArea;
